import { apiUrlEnv, buildUrlWithParameters } from '@sm360/helpers'
import axios from 'axios'

const SHOWROOM_API_URL = apiUrlEnv('REACT_APP_SHOWROOM_API_URL')

const PAYMENT_CALCULATOR_WIDGET_URL = apiUrlEnv('REACT_APP_PAYMENT_CALCULATOR_WIDGET_URL')

const config = {
    headers: { authorization: `Basic c20zNjA6c20zNjA=` },
}

/**
 * Get Translations
 * @param { String } lang - Language
 * @param { String } domain - Domain
 * @returns {Promise<{}>}
 */
export const getTranslationsAPIUrl = async (lang, domain) => {
    const apiUrl = `/${lang}/website/api/config/tags`

    const location = domain || window?.web_domain
    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    try {
        const response = await axios.get(urlComplete, config)
        return response.data
    } catch (e) {
        return Promise.reject(e)
    }
}

/**
 * Get widget payment calculator
 * @param { String } lang - Language
 * @param { String } vehicleId - Vehicle Id
 * @param { Object } dealerInfos - Dealer infos with orgId, orgUnitId, orgUnitProvince etc...
 * @returns {Promise<{}>}
 */
export const getCalculatorApiUrl = async (
    lang,
    vehicleId,
    dealerInfos,
    quoteId,
    hasShowroomV25,
    hasShowroomV2,
    domain,
    purchaseMethod,
    term,
    frequency,
    kmPerYearPlan,
    calculatorView
) => {
    const { orgId, orgUnitId, orgUnitProvince } = dealerInfos

    const baseUrl = `${PAYMENT_CALCULATOR_WIDGET_URL}/payment-calculator-360/render/modalbeta?`

    const paramsUrl = {
        location: orgUnitProvince,
        orgId,
        orgUnitId,
        lang,
        vehicleId,
        quoteId,
        hasShowroomV25,
        hasShowroomV2,
        category: 'inventory',
        isStandaloneVDP: true,
        domain: domain || window?.web_domain,
        purchaseMethod: purchaseMethod || 'cash',
        term: term || 0,
        frequency: frequency || 0,
        kmPerYearPlan: kmPerYearPlan || 0,
        calculatorView: calculatorView || 'POPUP',
    }

    const apiUrl = buildUrlWithParameters(baseUrl, paramsUrl)
    let data = {}
    try {
        const response = await axios.get(apiUrl)
        data = await response.data
    } catch (error) {
        console.log(error)
    }
    return data
}

/**
 * Get Quote Desking
 * @param { String } quoteId - Quote identifier
 * @param { String} token - Token identifier
 * @param { Object } dealerInfos - Dealer Infos
 * @returns {Promise<{}>}
 */
export const getShowroomQuoteAPIUrl = async (quoteId, token, dealerInfos) => {
    const apiUrl = `${SHOWROOM_API_URL}/service/v1/quote/desking/${quoteId}`

    const { lang, orgUnitProvince, orgId, orgUnitId } = dealerInfos

    let apiResponse = {}

    const defaultParams = {
        language: lang,
        location: orgUnitProvince,
        orgId,
        orgUnitId,
        includeMeta: true,
        module: 2,
    }

    const urlWithParams = buildUrlWithParameters(apiUrl, {
        ...defaultParams,
    })

    const config = {
        method: 'get',
        url: urlWithParams,
        headers: {
            'x-jwt': token,
            'Content-Type': 'application/json',
            'accept-language': `${lang}-CA`,
        },
    }

    try {
        const response = await axios(config)
        if (response) apiResponse = await response.data
        return apiResponse
    } catch (error) {
        return apiResponse
    }
}

/**
 * Generator JWT
 * @returns {Promise<{}>}
 */
export const getJwt = async () => {
    const apiUrl = `${SHOWROOM_API_URL}/service/v1/jwt?userName=public&password=public`

    let apiResponse = {}
    try {
        const response = await axios.get(apiUrl)
        if (response) apiResponse = await response.data
        return apiResponse
    } catch (error) {
        return apiResponse
    }
}

/**
 * Get Config from XMS
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getShowroom360ConfigGeneralApiUrl = async (lang, domain) => {
    let apiResponse = {}
    const apiUrl = `/${lang}/website/api/showroom360/config/general`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    try {
        const response = await axios.get(urlComplete, config)
        if (response) {
            apiResponse = response.data
        }
        return apiResponse
    } catch (e) {
        return apiResponse
    }
}
/**
 * Showroom save quote
 * @param {*} saveQuoteDetails Quote object to save
 * @param {*} config General configs for website
 * @param {*} lang selected language
 * @returns quoteId
 */

export const saveQuote = async (saveQuoteDetails, config, lang) => {
    const organizationId = config?.orgId
    const organizationUnitId = config?.orgUnitId
    const basePath = `${SHOWROOM_API_URL}/service/v1`
    const context = `orgId=${organizationId}&orgUnitId=${organizationUnitId}` + `&lang=${lang}&priceIncreaseRollCount=0&module=2`

    const url =
        saveQuoteDetails.showroomVehicleId && saveQuoteDetails.vehicleCondition.indexOf('new') === 0
            ? `${basePath}/quote/vehicle/listingNew/${saveQuoteDetails.showroomVehicleId}?${context}`
            : `${basePath}/quote/vehicle/listingUsed?${context}`

    const publicToken = await getJwt()
    return axios
        .put(url, saveQuoteDetails, {
            headers: {
                'x-jwt': publicToken,
            },
        })
        .then((result) => {
            const quoteId = result.data.id
            return Promise.resolve(quoteId)
        })
}

/**
 * Get Tagging configurations
 * @param {*} lang - Selected Language
 * @returns The Tagging configurations
 */
export const getTaggingConfigAPIUrl = async (lang, domain) => {
    let apiResponse = {}

    const apiUrl = `/${lang}/website/api/config/tagging`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config)
    if (response) {
        apiResponse = response.data || { taggingConfigurations: [] }
    }
    return apiResponse
}

/**
 * Get Showroom Payment Options
 * @param { Object } object - The default payload
 * @param { String } object.quoteId - QuoteId
 * @param { String } object.lang - Lang
 * @param { String } object.location - Location province
 * @param { String | Number } object.orgId - Organization Id
 * @param { String | Number } object.orgUnitId - Organization Unit Id
 * @param { Object } object.payload - Payload
 * @param { String } object.token - Token
 * @returns {Promise<{}>}
 */
export const getQuoteDeskingPaymentOptions = async ({ quoteId, lang, location, orgId, orgUnitId, payload, token }) => {
    let apiResponse = {}
    const apiUrl = `${SHOWROOM_API_URL}/service/v1/quote/desking/paymentOptions/${quoteId}?lang=${lang}&location=${location}&orgId=${orgId}&orgUnitId=${orgUnitId}&module=2&includeMeta=true`

    const headers = {
        headers: {
            'accept-language': `${lang}-CA`,
            'Content-Type': 'application/json',
            'x-jwt': token,
        },
    }
    try {
        const response = await axios.post(apiUrl, payload, headers)
        if (response) apiResponse = await response.data
        return apiResponse
    } catch (error) {
        return apiResponse
    }
}
