import { WindowProxy } from '@sm360/helpers'
import Cookies from 'js-cookie'
import React from 'react'

import { getShowroomQuoteAPIUrl, getQuoteDeskingPaymentOptions } from '../../apis/showroom360/showroom360.service'

const initialState = {
    isFetching: false,
    vehicle: {},
    similarVehicles: [],
    catalogSummary: {},
    extendedOrganizationUnit: {},
    hubName: '',
    vehiclePromo: {},
    showroom360ConfigVdp: {
        detailMetaConfiguration: {},
        detailNewCTAConfigurations: [],
        detailUsedCTAConfigurations: [],
        detailNewHighlightCardConfigurations: [],
        detailUsedHighlightCardConfigurations: [],
        newVDPConfig: {},
        usedVDPConfig: {},
        usedVDPSectionsConfig: [],
        newVDPSectionsConfig: [],
    },
    quoteId: '',
    calculatorWidget: '',
    articleVehicle: [],
    tradeInWidget: {
        tradeInTopOfThePage: '',
        tradeInBelowPicture: '',
        tradeInBelowCTA: '',
        tradeInAtTheBottom: '',
    },
    showroomQuote: {},
    paymentOptions: {},
    dealerInfos: {},
    XMSConfig: {
        pictureConfiguration: {},
        taggingConfigurations: [],
        tripleMathBoxConfiguration: {},
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetching': {
            return {
                ...state,
                isFetching: true,
            }
        }
        case 'fetchSucceed': {
            return {
                ...state,
                isFetching: false,
            }
        }
        case 'updateState': {
            return {
                ...state,
                ...action.state,
            }
        }
        case 'updateShowroomQuote': {
            return {
                ...state,
                showroomQuote: action.showroomQuote,
            }
        }
        case 'updatePaymentOptions': {
            return {
                ...state,
                paymentOptions: action.paymentOptions,
            }
        }
        case 'updatePurchaseMethod': {
            return {
                ...state,
                paymentOptions: action.paymentOptions,
            }
        }
        case 'updateVehicle': {
            return {
                ...state,
                vehicle: action.vehicle,
            }
        }
        case 'init': {
            return {
                ...state,
                vehicle: action.vehicle,
                similarVehicles: action.similarVehicles,
                catalogSummary: action.catalogSummary,
                extendedOrganizationUnit: action.extendedOrganizationUnit,
                isFetching: true,
                hubName: action.hubName,
                vehiclePromo: action.vehiclePromo,
                showroom360ConfigVdp: action.showroom360ConfigVdp,
                quoteId: action.quoteId,
                calculatorWidget: action.calculatorWidget,
                articleVehicle: action.articleVehicle,
                tradeInWidget: action.tradeInWidget,
                showroomQuote: action.showroomQuote,
                dealerInfos: action.dealerInfos,
                XMSConfig: action.XMSConfig,
                paymentOptions: action.paymentOptions,
            }
        }
        default:
            return state
    }
}

const VdpContext = React.createContext(initialState)

const VdpProvider = ({ initialData, children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    if (!state.isFetching) {
        if (initialData) {
            dispatch({ type: 'init', ...initialData })
        }
    }

    React.useEffect(() => {
        fetchShowroomQuote()
    }, [state.quoteId])

    React.useEffect(() => {
        if (WindowProxy) {
            WindowProxy.document.addEventListener('sm360.widgets.garage.added', () => fetchShowroomQuote())
            WindowProxy.document.addEventListener('sm360.widgets.tradein.created', () => fetchShowroomQuote(4000))
            WindowProxy.document.addEventListener('sm360.calculator.vdp.modal.close', (e) => {
                if (e?.detail?.paymentOptions?.purchaseMethod !== 'cash') {
                    updateVehiclePurchaseMethod(e?.detail?.paymentOptions)
                    dispatch({ type: 'updatePaymentOptions', paymentOptions: e?.detail?.paymentOptions })
                } else {
                    const updatedPaymentOptions = {
                        paymentOptions: {
                            ...state.paymentOptions,
                            purchaseMethod: 'cash',
                            transactionToken: e?.detail?.paymentOptions?.transactionToken,
                        },
                    }
                    dispatch({ type: 'updatePaymentOptions', paymentOptions: updatedPaymentOptions.paymentOptions })
                }
            })
        }
    }, [])

    const updateVehiclePurchaseMethod = async (paymentOptionsObj) => {
        const newVehicle = {
            ...state.vehicle,
            paymentOptions: {
                ...state.vehicle.paymentOptions,
                [paymentOptionsObj?.purchaseMethod]: {
                    ...paymentOptionsObj.finance, // This will overwrite the existing finance values
                },
            },
        }
        dispatch({ type: 'updateVehicle', vehicle: newVehicle })
    }

    const fetchShowroomQuote = async (timeOut = 0) => {
        const cookie = Cookies.get('auth-token')
        if (cookie) {
            setTimeout(async function () {
                const showroomQuote = await getShowroomQuoteAPIUrl(state.quoteId, cookie, state.dealerInfos)
                if (showroomQuote) {
                    dispatch({ type: 'updateShowroomQuote', showroomQuote })
                }
                const { orgId, orgUnitId, orgUnitProvince, lang } = state.dealerInfos
                const paramsPaymentOptions = {
                    quoteId: state.quoteId || '',
                    lang,
                    location: orgUnitProvince,
                    orgId: parseInt(orgId),
                    orgUnitId: parseInt(orgUnitId),
                    payload: {
                        transactionToken: showroomQuote?.transactionToken,
                        saleType: showroomQuote?.saleType,
                        taxPlan: showroomQuote?.taxPlan,
                        vehicleCondition: showroomQuote?.vehicleCondition,
                        odometer: showroomQuote?.odometer,
                        purchaseMethod: showroomQuote?.purchaseMethod,
                        financePlan: showroomQuote?.financePlan,
                        kmPerYearPlan: showroomQuote?.kmPerYearPlan,
                        paymentFrequency: showroomQuote?.paymentFrequency,
                        term: showroomQuote?.term,
                        cashDown: showroomQuote?.cashDown,
                        tradeIn: showroomQuote?.tradeIn,
                        lien: showroomQuote?.lien,
                        financeOptions: showroomQuote?.financeOptions,
                    },
                    token: cookie,
                }

                const initPaymentOptions = await getQuoteDeskingPaymentOptions(paramsPaymentOptions)
                dispatch({ type: 'updatePaymentOptions', paymentOptions: initPaymentOptions })
            }, timeOut)
        }
    }

    return <VdpContext.Provider value={{ state, dispatch }}>{children}</VdpContext.Provider>
}

VdpProvider.propTypes = {}

VdpProvider.defaultProps = {}

export { VdpContext, VdpProvider }
