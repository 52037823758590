import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'

import {
    Heading,
    PackageOptionsAndAccessories,
    VehicleDescription,
    VehicleFeatures,
    VehicleHighlightCards,
    VehicleSpecs,
    VehicleWarranty,
} from '@sm360/components'

import { useTranslation } from '@sm360/hooks'
import { VdpContext } from '../../configs/vdp-view/state.manager'

const PageSections = ({ extraClasses }) => {
    const {
        state: { vehicle, showroom360ConfigVdp, quoteId },
    } = React.useContext(VdpContext)

    const { t } = useTranslation()

    const baseClass = 's360-o-pageSections'
    const classNames = clsx(baseClass, extraClasses)

    const {
        newVehicle,
        model,
        consumption,
        odometer,
        frameDescription,
        bodyStyle,
        doors,
        passengers,
        engine,
        features,
        extraOptions,
        fuel,
        transmissionDetails,
        driveTrainDetails,
        exteriorColor,
        interiorColor,
        stockNo,
        serialNo,
        description,
        paymentOptions,
        batteryCapacity,
        batteryRange,
        warrantyDescription,
    } = vehicle

    const {
        detailNewHighlightCardConfigurations,
        detailUsedHighlightCardConfigurations,
        usedVDPSectionsConfig,
        newVDPSectionsConfig,
    } = showroom360ConfigVdp

    const sectionsConfig = newVehicle ? newVDPSectionsConfig : usedVDPSectionsConfig
    const highlights = newVehicle ? detailNewHighlightCardConfigurations : detailUsedHighlightCardConfigurations

    const renderVehicleComponent = ({ slug, title, index }) => {
        const commonProps = {
            headingTitle: title,
            key: index,
        }

        switch (slug) {
            case 'specifications':
                return (
                    <VehicleSpecs
                        {...commonProps}
                        specs={{
                            consumption,
                            odometer,
                            frameCategory: frameDescription?.frameCategory?.frameCategory,
                            bodyStyle: bodyStyle?.name,
                            doors,
                            passengers,
                            engineType: engine?.type,
                            engineDescription: engine?.description,
                            fuel: fuel?.name,
                            transmission: transmissionDetails?.name,
                            gears: transmissionDetails?.transGears,
                            driveTrain: driveTrainDetails?.name,
                            exteriorColor: exteriorColor?.color,
                            interiorColor: interiorColor?.color,
                            stockNumber: stockNo,
                            serialNumber: serialNo,
                            batteryCapacity,
                            batteryRange,
                        }}
                        extraClasses={`${baseClass}__vehicleSpecs`}
                    />
                )

            case 'description':
                return <VehicleDescription {...commonProps} description={description} model={model?.name} />

            case 'options-accessories':
                return (
                    <PackageOptionsAndAccessories
                        {...commonProps}
                        optionsAndAccessories={paymentOptions?.packageOptionsAndAccessories}
                        displayLimit={false}
                        showEmptyElem={false}
                        headingClass='il-heading3'
                        extraClasses={`${baseClass}__vehicleOptions`}
                    >
                        <Heading extraClasses={`${baseClass}__header il-heading3`} Tag='h2' variant='heading3'>
                            {title || t('vehicle.accessoriesAndOptions')}
                        </Heading>
                    </PackageOptionsAndAccessories>
                )
            // Key Features for used vehicles
            case 'key-features':
                return (
                    <VehicleFeatures
                        {...commonProps}
                        features={features}
                        newVehicle={newVehicle}
                        extraOptions={extraOptions}
                        extraClasses={`${baseClass}__vehicleFeatures`}
                    />
                )
            // Key Features for new vehicles
            case 'equipments':
                return (
                    <VehicleFeatures
                        {...commonProps}
                        features={features}
                        newVehicle={newVehicle}
                        extraOptions={extraOptions}
                        extraClasses={`${baseClass}__vehicleFeatures`}
                    />
                )

            case 'highlights':
                return highlights?.length > 0 ? (
                    <VehicleHighlightCards
                        {...commonProps}
                        vehicleInventory={vehicle}
                        highlights={highlights}
                        quoteId={quoteId}
                    />
                ) : null

            case 'warranties':
                return !newVehicle ? (
                    <VehicleWarranty
                        {...commonProps}
                        warrantyDescription={warrantyDescription}
                        extraClasses={`${baseClass}__vehicleWarranty`}
                    />
                ) : null

            default:
                return null
        }
    }

    return (
        <div className={classNames}>
            {sectionsConfig.map(({ active, title, slug }, index) => active && renderVehicleComponent({ slug, title, index }))}
        </div>
    )
}

PageSections.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

PageSections.defaultProps = {
    extraClasses: '',
}

export default PageSections
